import React, { useContext, useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import DatePicker from "react-datepicker"
import moment from "moment"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import ToggleKeyboard from "../components/ToggleKeyboard"
import ToggleMute from "../components/ToggleMute"

export default function RegistrationFormB() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")
  const [nokAgree, setNokAgree] = useState(false)

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value.toUpperCase(),
    })
  }

  useEffect(() => {
    if (inputName === "nok_mobile_number") {
      setLayoutName("numeric")
    } else {
      setLayoutName("default")
    }
  }, [inputName])

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-5 font-weight-bold mt-4">
          B. Contact Person/Next of Kin - In Case of Emergency
        </h1>

        <div className="row">
          <div className="col-8">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-5 mt-4">
                Name:
              </h3>
              <div className="col-7">
                <input
                  type="text"
                  name="nok_name"
                  className="form-control"
                  onFocus={() => setInputName("nok_name")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.nok_name}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-5 mt-4">
                Relationship:
              </h3>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="nok_relationship"
                  onFocus={() => setInputName("nok_relationship")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.nok_relationship}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-5 mt-4">
                Residential
                <br />
                Address:
              </h3>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="nok_residential_address"
                  onFocus={() => setInputName("nok_residential_address")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "2em",
                  }}
                  value={user.nok_residential_address}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-5 mt-4">
                Residential No:
              </h3>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  name="nok_residential_number"
                  onFocus={() => setInputName("nok_residential_number")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.nok_residential_number}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-5 mt-4">
                Mobile No:
              </h3>
              <div className="col-7">
                <input
                  type="text"
                  name="nok_mobile_number"
                  onFocus={() => setInputName("nok_mobile_number")}
                  className="form-control"
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.nok_mobile_number}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-check col-8">
          <input
            className="form-check-input"
            type="checkbox"
            checked={nokAgree}
            onChange={() => {
              setNokAgree(!nokAgree)
              saveUser({
                nok_agree: !nokAgree,
              })
            }}
            style={{
              transform: "scale(5)",
              marginLeft: "1em",
              marginTop: "3em",
            }}
          />
          <label
            className="form-check-label h3"
            style={{ marginLeft: "3em", marginTop: "1em" }}
            htmlFor="defaultCheck1"
          >
            I declare that the Next of Kin provided is at least 21 years of age
            at the time of this application.
          </label>
        </div>
        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleKeyboard
            isActive={user.isKeyboardActive}
            onClick={() => {
              saveUser({
                ...user,
                isKeyboardActive: !user.isKeyboardActive,
              })
            }}
          />
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>
        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            disabled={
              !user.nok_name ||
              !user.nok_relationship ||
              !user.nok_residential_address ||
              !user.nok_residential_number ||
              !user.nok_agree ||
              !user.nok_mobile_number
            }
            onClick={() => {
              saveDraftUser(user)
              navigate("/registration-form-c")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/registration-form-a")}
          />
        </div>
        {user.isKeyboardActive && (
          <div
            className="fixed-bottom w-50 p-4"
            style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} Q W E R T Y U I O P [ ] \\",
                  "{lock} A S D F G H J K L ; ' {enter}",
                  "{shift} Z X C V B N M , . / {shift}",
                  ".com @ {space}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
              }}
            />
          </div>
        )}
      </div>
    </Background>
  )
}
